// src/components/shared/Loading.js

import React from 'react';
import PropTypes from 'prop-types';
import './Loading.css'; // Ensure that Loading.css contains the appropriate spinner styles

/**
 * Loading Component
 * Displays a loading spinner with a message.
 * 
 * Props:
 * - message (string): The loading message to display below the spinner.
 * - spinnerColor (string): The color of the spinner border.
 */
const Loading = ({ message = "Please wait while we load your content.", spinnerColor = "#4CAF50" }) => (
  <div className="loading-container" role="status" aria-live="polite" aria-busy="true">
    <div
      className="spinner"
      style={{ borderColor: `${spinnerColor} transparent transparent transparent` }}
      aria-hidden="true"
    ></div>
    <h2>Loading...</h2>
    <p>{message}</p>
  </div>
);

Loading.propTypes = {
  message: PropTypes.string,
  spinnerColor: PropTypes.string
};

export default Loading;
