import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import ProtectedRoute from './ProtectedRoute';
import Loading from './components/shared/Loading';
import './styles/global.css';

// Lazy load pages
// Public Pages
const Home = lazy(() => import('./components/pages/Home'));
const About = lazy(() => import('./components/pages/About'));
const Login = lazy(() => import('./components/pages/Login'));
const Register = lazy(() => import('./components/pages/Register'));
const Help = lazy(() => import('./components/pages/Help'));
const Contact = lazy(() => import('./components/pages/Contact'));
const PrivacyPolicy = lazy(() => import('./components/pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./components/pages/TermsOfService'));

// Originally was pointing to `Promotions` as `ReferralBonus`.
// If `ReferralBonus.js` is your main promotions page, keep it.
// Otherwise, if you have `PromotionManagement.js`, swap it in below.
// For now, we respect the current code and keep ReferralBonus.
const Promotions = lazy(() => import('./components/promotions/PromotionManagement'));

const Callback = lazy(() => import('./components/pages/Callback'));
const ErrorPage = lazy(() => import('./components/pages/ErrorPage'));
const NotFound = lazy(() => import('./components/pages/NotFound'));

// User-Related Pages
// Ensure UserProfile.js exists in the users folder.
const UserDashboard = lazy(() => import('./components/users/UserDashboard'));
const Profile = lazy(() => import('./components/pages/Profile'));


// Notifications folder is lowercase, so adjust the path:
const Notifications = lazy(() => import('./components/notifications/Notifications'));

// Appointment-Related Pages
const AppointmentHistory = lazy(() => import('./components/appointments/AppointmentHistory'));
const AppointmentReminder = lazy(() => import('./components/appointments/AppointmentReminder'));
const AppointmentQueue = lazy(() => import('./components/appointments/AppointmentQueue'));

// Loyalty & Promotions
const LoyaltyProgram = lazy(() => import('./components/loyalty/LoyaltyProgram'));
const RedeemRewards = lazy(() => import('./components/loyalty/RedeemRewards'));
const ApplyPromotion = lazy(() => import('./components/promotions/ApplyPromotion'));

// Messaging
const Messaging = lazy(() => import('./components/messaging/Messaging'));

// Subscriptions
// If you don’t have Subscriptions.js but have SubscriptionManagement.js,
// we’ll use that to represent the /subscriptions route.
const Subscriptions = lazy(() => import('./components/subscriptions/SubscriptionTierManagement'));

// Payments
const PaymentHistory = lazy(() => import('./components/payments/PaymentHistory'));

// Files
const FileUpload = lazy(() => import('./components/files/FileUpload'));

// Surveys
// If there’s no Surveys.js but you have SurveyAnalysis.js, use that:
const Surveys = lazy(() => import('./components/surveys/SurveyAnalysis'));

// Onboarding
const Onboarding = lazy(() => import('./components/onboarding/Onboarding'));

// Technicians
// If ServiceAvailabilitySettings.js is your intended main component for service-area:
const TechnicianDashboard = lazy(() => import('./components/technicians/TechnicianDashboard'));
const TechnicianPerformance = lazy(() => import('./components/technicians/TechnicianPerformanceAnalytics'));
const ServiceAreaManagement = lazy(() => import('./components/technicians/ServiceAvailabilitySettings'));

// Admin
const AdminDashboard = lazy(() => import('./components/admin/AdminDashboard'));
const ServiceManagement = lazy(() => import('./components/admin/ServiceManagement'));
const StaffManagement = lazy(() => import('./components/admin/StaffManagement'));
const PermissionsManagement = lazy(() => import('./components/admin/PermissionsManagement'));
const AuditLog = lazy(() => import('./components/admin/AuditLog'));
const AnalyticsDashboard = lazy(() => import('./components/admin/AnalyticsDashboard'));

function App() {
  return (
    <div>
      <Navbar />
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/help" element={<Help />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/promotions" element={<Promotions />} />
          <Route path="/callback" element={<Callback />} />

          {/* User Protected Routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <UserDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/appointments/history"
            element={
              <ProtectedRoute>
                <AppointmentHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/appointments/reminder"
            element={
              <ProtectedRoute>
                <AppointmentReminder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/appointments/queue"
            element={
              <ProtectedRoute>
                <AppointmentQueue />
              </ProtectedRoute>
            }
          />
          <Route
            path="/loyalty-program"
            element={
              <ProtectedRoute>
                <LoyaltyProgram />
              </ProtectedRoute>
            }
          />
          <Route
            path="/loyalty/redeem"
            element={
              <ProtectedRoute>
                <RedeemRewards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/apply-promotion"
            element={
              <ProtectedRoute>
                <ApplyPromotion />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messaging"
            element={
              <ProtectedRoute>
                <Messaging />
              </ProtectedRoute>
            }
          />
          <Route
            path="/subscriptions"
            element={
              <ProtectedRoute>
                <Subscriptions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payments/history"
            element={
              <ProtectedRoute>
                <PaymentHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/file-upload"
            element={
              <ProtectedRoute>
                <FileUpload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/surveys"
            element={
              <ProtectedRoute>
                <Surveys />
              </ProtectedRoute>
            }
          />
          <Route
            path="/onboarding"
            element={
              <ProtectedRoute>
                <Onboarding />
              </ProtectedRoute>
            }
          />

          {/* Technician Protected Routes */}
          <Route
            path="/technicians/dashboard"
            element={
              <ProtectedRoute>
                <TechnicianDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/technicians/performance"
            element={
              <ProtectedRoute>
                <TechnicianPerformance />
              </ProtectedRoute>
            }
          />
          <Route
            path="/technicians/service-area"
            element={
              <ProtectedRoute>
                <ServiceAreaManagement />
              </ProtectedRoute>
            }
          />

          {/* Admin Protected Routes */}
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/services"
            element={
              <ProtectedRoute>
                <ServiceManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/staff"
            element={
              <ProtectedRoute>
                <StaffManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/permissions"
            element={
              <ProtectedRoute>
                <PermissionsManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/audit-log"
            element={
              <ProtectedRoute>
                <AuditLog />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/analytics"
            element={
              <ProtectedRoute>
                <AnalyticsDashboard />
              </ProtectedRoute>
            }
          />

          {/* Error and Fallback Routes */}
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
