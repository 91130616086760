import { useAuth0 } from "@auth0/auth0-react";

const useAuth = () => {
  const {
    isAuthenticated,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    isLoading,
    error,
  } = useAuth0();

  /**
   * Extracts the primary role from the user's custom claims.
   * @returns {string|null} The primary role, or null if not available.
   */
  const getUserRole = () => {
    if (!user) return null;
    const roles = user["https://auth.pawdicure-staging.com/roles"] || [];
    return roles.length > 0 ? roles[0] : null;
  };

  /**
   * Checks if the user has a specific role.
   * @param {string} role - The role to check.
   * @returns {boolean} True if the user has the role.
   */
  const hasRole = (role) => {
    if (!user) return false;
    const roles = user["https://auth.pawdicure-staging.com/roles"] || [];
    return roles.includes(role);
  };

  /**
   * Retrieves an access token with error handling.
   * @returns {Promise<string>} The access token.
   */
  const getTokenWithHandling = async () => {
    try {
      return await getAccessTokenSilently();
    } catch (err) {
      console.error("[useAuth] Error retrieving access token:", err);
      throw new Error("Authentication failed. Please log in again.");
    }
  };

  /**
   * Determines if the user is an admin based on roles.
   * @returns {boolean} True if the user is an admin.
   */
  const isAdmin = hasRole("admin");

  return {
    isAuthenticated,
    user,
    login: loginWithRedirect,
    logout,
    getAccessTokenSilently,
    getTokenWithHandling,
    isLoading,
    error,
    getUserRole,
    hasRole,
    isAdmin,
  };
};

export default useAuth;
