// src/utils/authHelpers.js

/**
 * Decodes a JWT token to extract its payload.
 * @param {string} token - The JWT token to decode.
 * @returns {object|null} The decoded payload or null if the token is invalid.
 */
export const decodeJWT = (token) => {
  if (!token || typeof token !== 'string') {
    console.error("Invalid token provided for decoding.");
    return null;
  }

  try {
    const parts = token.split(".");
    if (parts.length !== 3) {
      console.error("Invalid JWT format. Expected 3 parts separated by dots.");
      return null;
    }

    const base64Url = parts[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${c.charCodeAt(0).toString(16).padStart(2, "0")}`)
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};

/**
 * Checks if the current user has a specific role.
 * @param {string[]} roles - The roles assigned to the user.
 * @param {string} requiredRole - The role to check for.
 * @returns {boolean} True if the user has the required role, otherwise false.
 */
export const hasRole = (roles, requiredRole) => {
  if (!Array.isArray(roles)) {
    console.warn("hasRole: 'roles' should be an array.");
    return false;
  }
  return roles.includes(requiredRole);
};

/**
 * Retrieves the user's roles from a JWT token.
 * @param {string} token - The JWT token containing roles in its payload.
 * @param {string} namespace - The custom namespace for roles in the JWT payload.
 * @returns {string[]} An array of roles or an empty array if roles are not found.
 */
export const getUserRoles = (token, namespace) => {
  if (!namespace || typeof namespace !== 'string') {
    console.warn("getUserRoles: A valid 'namespace' string is required.");
    return [];
  }

  const decodedToken = decodeJWT(token);
  if (!decodedToken) return [];

  const roles = decodedToken[namespace];
  if (!Array.isArray(roles)) {
    // If roles are not found or not an array, return empty array
    return [];
  }

  return roles;
};
