import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import Loading from "./components/shared/Loading";
import { getUserRoles, hasRole } from "./utils/authHelpers";

const ProtectedRoute = ({ children, requiredRoles = [], roleNamespace = process.env.REACT_APP_AUTH0_NAMESPACE }) => {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  // If roles are required, check user roles
  if (requiredRoles.length > 0) {
    // This logic assumes `getUserRoles` can extract roles just from user.sub or you might need the actual token
    const token = user && user.sub;
    const userRoles = getUserRoles(token, roleNamespace);

    // Check if user has at least one required role
    const hasAccess = requiredRoles.some((role) => hasRole(userRoles, role));
    if (!hasAccess) {
      return <Navigate to="/unauthorized" />;
    }
  }

  // Create a component for withAuthenticationRequired
  const WrappedComponent = () => <>{children}</>;

  const Component = withAuthenticationRequired(WrappedComponent, {
    onRedirecting: () => <Loading />,
  });

  return <Component />;
};

export default ProtectedRoute;
