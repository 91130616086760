// src/components/navbar/Navbar.js
import React from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth"; // Assuming useAuth is a default export
import "./Navbar.css";

const Navbar = () => {
  const { isAuthenticated, isAdmin, login, logout, user } = useAuth();

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">Pawdicure</Link>
      </div>
      <ul className="navbar-links">
        <li>
          <Link to="/">Home</Link>
        </li>
        {isAuthenticated && (
          <>
            <li>
              <Link to="/profile">Profile</Link>
            </li>
            <li>
              <Link to="/appointments">Appointments</Link>
            </li>
            {isAdmin && (
              <li>
                <Link to="/admin/dashboard">Admin Dashboard</Link>
              </li>
            )}
            <li className="navbar-user">
              Welcome, {user?.name || "User"}
            </li>
          </>
        )}
        {!isAuthenticated ? (
          <li>
            <button className="navbar-button" onClick={login}>
              Log In
            </button>
          </li>
        ) : (
          <li>
            <button className="navbar-button" onClick={logout}>
              Log Out
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
