// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './styles/global.css';

// Extract environment variables with fallback error handling
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

if (!domain || !clientId || !audience) {
  console.error(
    "Missing Auth0 environment variables. Please ensure REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, and REACT_APP_AUTH0_AUDIENCE are set in your .env file."
  );
  throw new Error("Auth0 configuration error: Missing required environment variables.");
}

// Configure Auth0Provider
const auth0Config = {
  domain,
  clientId,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/callback`, // Ensure redirect URI matches Auth0 settings
    audience, // Specify the audience for API access tokens
    scope: "openid profile email read:roles", // Request user profile info and roles
  },
};

// Render the application
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider {...auth0Config}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>
);
