// src/components/footer/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

/**
 * Footer Component
 * Displays navigation links and additional footer content.
 */
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">
          <Link to="/about">About Us</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-service">Terms of Service</Link>
        </div>
        <div className="footer-copyright">
          &copy; {new Date().getFullYear()} Pawdicure. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
